<template>
	<div class="element-box">
		<div class="row">
			<div class="col">
				<h5 class="form-header">
					Расписание сотрудника {{ user.name }}
				</h5>
				<div class="form-desc">
					Измените необходимые данные и нажмите сохранить
				</div>
			</div>
			<div class="col col-auto">
				<b-form-select
					id="date-month"
					name="date-month"
					v-model="calendarMonth"
					:options="months"
					class="form-control form-control-sm rounded bright"
					@change="getSchedule"
				></b-form-select>
			</div>
			<div class="col col-auto">
				<b-form-select
					id="date-month"
					name="date-month"
					v-model="calendarYear"
					:options="years"
					class="form-control form-control-sm rounded bright"
					@change="getSchedule"
				></b-form-select>
			</div>
		</div>

		<div class="schedule-calendar">
			<table class="table">
				<thead>
					<tr>
						<th>ПН</th>
						<th>ВТ</th>
						<th>СР</th>
						<th>ЧТ</th>
						<th>ПТ</th>
						<th>СБ</th>
						<th>ВС</th>
					</tr>
				</thead>
				<tbody>
					<tr v-for="(w, i) in generateMonth" :key="i">
						<td v-for="(d, j) in w" :key="j" :class="getScheduleColor(user.id, d.date)">
							<schedule-item
								v-if="d.isCurrent"
								:user="user"
								:calendar="d"
								:schedule="getScheduleItem(user.id, d.date)"
								@delete-schedule="getSchedule"
							/>
							<div v-else class="schedule-box disabled">
								{{ d.name }}
							</div>
						</td>
					</tr>
				</tbody>
			</table>
		</div>
		<div class="form-buttons-w">
			<b-button
				v-b-modal="'schedule-add-modal'"
				class="btn btn-primary"
				type="button"
				variant="primary"
				name="button"
				:disabled="addModalDisabled"
			>
				Добавить / изменить
			</b-button>
			<b-button class="btn btn-grey" @click="backToScheduleTable">Назад</b-button>
		</div>

		<b-modal
			id="schedule-add-modal"
			centered
			footer-class="p2"
			title="Добавить расписание"
			ok-variant="success"
			ok-title="Сохранить"
			cancel-title="Отмена"
			@ok="addSchedule()"
		>
			<div class="row">
				<div class="col-sm-6">
					<div style="text-align: center;">
						<date-picker
							id="schedule-date-input"
							v-model="addScheduleData.dates"
							:disabled-date="disabledRange"
							:default-value="currentDate"
							:inline="true"
							:multiple="true"
							placeholder="Введите дату открытия"
							value-type="format"
						/>
					</div>
				</div>
				<div class="col-sm-6">
					<b-form-group id="schedule-type-input-group" label="Тип" label-for="schedule-type-input">
						<b-form-select
							id="schedule-type-input"
							name="schedule-type-input"
							v-model="addScheduleData.absenceReason"
							:options="absenceReasonsTypes"
						></b-form-select>
					</b-form-group>
					<template v-if="addScheduleData.absenceReason === ''">
						<div class="row">
							<div class="col-sm-6">
								<b-form-group id="schedule-begin-hour-input-group" label="" label-for="schedule-begin-hour-input">
									<b-form-select
										id="schedule-begin-hour-input"
										name="schedule-begin-hour-input"
										v-model="addScheduleData.beginTimeHour"
										:options="hours"
									></b-form-select>
								</b-form-group>
							</div>
							<div class="col-sm-6">
								<b-form-group id="schedule-begin-minute-input-group" label="" label-for="schedule-begin-minute-input">
									<b-form-select
										id="schedule-begin-minute-input"
										name="schedule-begin-minute-input"
										v-model="addScheduleData.beginTimeMinute"
										:options="minutes"
									></b-form-select>
								</b-form-group>
							</div>
						</div>
						<div class="row">
							<div class="col-sm-6">
								<b-form-group id="schedule-end-hour-input-group" label="" label-for="schedule-end-hour-input">
									<b-form-select
										id="schedule-end-hour-input"
										name="schedule-end-hour-input"
										v-model="addScheduleData.endTimeHour"
										:options="hours"
									></b-form-select>
								</b-form-group>
							</div>
							<div class="col-sm-6">
								<b-form-group id="schedule-end-minute-input-group" label="" label-for="schedule-end-minute-input">
									<b-form-select
										id="schedule-end-minute-input"
										name="schedule-end-minute-input"
										v-model="addScheduleData.endTimeMinute"
										:options="minutes"
									></b-form-select>
								</b-form-group>
							</div>
						</div>
					</template>
				</div>
			</div>
		</b-modal>
	</div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import 'vue2-datepicker/locale/ru';
import {routeNames} from '@/router/constants';
import ScheduleItem from './components/ScheduleItem.vue';

export default {
	components: {
		DatePicker,
		ScheduleItem,
	},
	name: 'ScheduleUpdateForm',
	data() {
		const d = new Date();
		const years = [];

		if (d.getFullYear() > 2024) {
			const diff = d.getFullYear() - 2024;
			for (let i = 0; i < diff; i += 1) {
				years.push(2024 + i);
			}
		}

		for (let i = 0; i < 3; i += 1) {
			years.push(d.getFullYear() + i);
		}

		const hours = [];

		for (let i = 0; i < 24; i += 1) {
			if (i < 10) {
				hours.push('0' + i);
				continue;
			}

			hours.push(i.toString());
		}

		const minutes = [];

		for (let i = 0; i < 60; i += 1) {
			if (i < 10) {
				minutes.push('0' + i);
				continue;
			}

			minutes.push(i.toString());
		}

		let nowDate = d.getFullYear().toString();
		if (d.getMonth() + 1 >= 10) {
			nowDate += `${(d.getMonth() + 1)}`;
		} else {
			nowDate += `0${(d.getMonth() + 1)}`;
		}

		let nowMonth = nowDate;

		if (d.getDate() >= 10) {
			nowDate += `${d.getDate()}`;
		} else {
			nowDate += `0${d.getDate()}`;
		}

		return {
			filters: {},
			backToUrl: '',
			user: {
				id: '',
				name: '',
			},
			addScheduleData: {
				userId: '',
				dates: [],
				beginTime: '',
				beginTimeHour: '09',
				beginTimeMinute: '00',
				endTime: '',
				endTimeHour: '23',
				endTimeMinute: '00',
				absenceReason: '',
			},
			calendarMonth: d.getMonth(),
			calendarYear: d.getFullYear(),
			hours,
			minutes,
			months: [
				{value: 0, text: 'Январь'},
				{value: 1, text: 'Февраль'},
				{value: 2, text: 'Март'},
				{value: 3, text: 'Апрель'},
				{value: 4, text: 'Май'},
				{value: 5, text: 'Июнь'},
				{value: 6, text: 'Июль'},
				{value: 7, text: 'Август'},
				{value: 8, text: 'Сентябрь'},
				{value: 9, text: 'Октябрь'},
				{value: 10, text: 'Ноябрь'},
				{value: 11, text: 'Декабрь'},
			],
			years,
			absenceReasonsTypes: [
				{value: '', text: 'Рабочее время'},
				{value: 'Б', text: 'Больничный'},
				{value: 'В', text: 'Выходной'},
				{value: 'БС', text: 'Отпуск без сохранения оклада'},
				{value: 'О', text: 'Отпуск'},
				{value: 'Отгул', text: 'Отгул'},
				{value: 'К', text: 'Командировка'},
				{value: 'У', text: 'Увольнение'},
				{value: 'Тех', text: 'Технические проблемы'},
			],
			now: new Date(d.getFullYear(), d.getMonth(), d.getDate()),
			nowDate: parseInt(nowDate, 10),
			nowMonth: parseInt(nowMonth, 10),
		};
	},

	computed: {
		...mapGetters([
			'passportUsers', 'staffSchedule'
		]),
		generateMonth() {
			const month = [];
			const d = new Date(this.calendarYear, this.calendarMonth, 1);
			let weekDay = d.getDay();

			if (weekDay === 0) {
				weekDay = 7;
			}

			let startDate = d;

			if (weekDay !== 1) {
				startDate = new Date(d.getFullYear(), d.getMonth(), (weekDay - 2)*-1);
			}

			let dayCount = 0;

			for (let i = 0; i < 6; i += 1) {
				const days = [];
				let pushDays = true;

				for (let j = 0; j < 7; j += 1) {
					const c = new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate() + dayCount);

					const d = c.getDate();
					const m = c.getMonth();

					if (m !== this.calendarMonth && j === 0 && i > 0) {
						pushDays = false;
						break;
					}

					let nowDate = c.getFullYear().toString();
					if (c.getMonth() + 1 >= 10) {
						nowDate += `${(c.getMonth() + 1)}`;
					} else {
						nowDate += `0${(c.getMonth() + 1)}`;
					}

					if (c.getDate() >= 10) {
						nowDate += `${c.getDate()}`;
					} else {
						nowDate += `0${c.getDate()}`;
					}

					nowDate = parseInt(nowDate, 10);

					days.push({
						date: c.getFullYear() + '-' + (m + 1 < 10 ? '0'+(m + 1) : m + 1) + '-' + (d < 10 ? '0'+d : d),
						name: (d < 10 ? '0'+d : d) + '.' + (m + 1 < 10 ? '0'+(m + 1) : m + 1),
						isCurrent: m === this.calendarMonth,
						isDisabled: nowDate < this.nowDate,
					});

					dayCount += 1;
				}

				if (pushDays) {
					month.push(days);
				}
			}

			return month;
		},

		currentDate() {
			const d = new Date(this.calendarYear, this.calendarMonth, 1);
			return d;
		},

		addModalDisabled() {
			let calendarDate = this.calendarYear.toString();
			if (this.calendarMonth + 1 >= 10) {
				calendarDate += `${(this.calendarMonth + 1)}`;
			} else {
				calendarDate += `0${(this.calendarMonth + 1)}`;
			}

			calendarDate = parseInt(calendarDate, 10);

			return calendarDate < this.nowMonth;
		},
	},

	watch: {
		'calendarYear': {
			deep: true,
			handler(value) {
				this.filters.year = value;
			}
		},
		'calendarMonth': {
			deep: true,
			handler(value) {
				this.filters.month = value;
			}
		}
	},

	methods: {
		...mapActions([
			'GET_USERS_FROM_API',
			'HELPDESK_GET_STAFF_SCHEDULE_FROM_API',
			'HELPDESK_CREATE_STAFF_SCHEDULE',
			'ADD_NOTIFICATION_TO_QUERY',
		]),

		disabledRange: function (date) {
			return date < this.now;
		},

		backToScheduleTable() {
			if (this.backToUrl !== '') {
				this.$router.push({path: decodeURI(this.backToUrl)});
			} else {
				this.$router.push({name: routeNames.passport.schedule.read, query: this.filters});
			}
		},

		async getSchedule() {
			const currentMonth = this.calendarMonth + 1;
			const dateParam = this.calendarYear.toString() + '-' + (currentMonth < 10 ? '0' + currentMonth : currentMonth);

			await this.HELPDESK_GET_STAFF_SCHEDULE_FROM_API({userId: this.user.id, fromMonth: dateParam, toMonth: dateParam}).catch(() => {
				this.ADD_NOTIFICATION_TO_QUERY({
					type: 'error',
					title: 'Ошибка',
					message: 'Произошла ошибка при получении списка расписаний'
				});
			});
		},

		getScheduleItem(userId, date) {
			for (let i = 0; i < this.staffSchedule.length; i += 1) {
				if (this.staffSchedule[i].userId === userId && this.staffSchedule[i].date === date) {
					return this.staffSchedule[i];
				}
			}

			return null;
		},

		getScheduleColor(userId, date) {
			for (let i = 0; i < this.staffSchedule.length; i += 1) {
				if (this.staffSchedule[i].userId === userId && this.staffSchedule[i].date === date) {
					if (this.staffSchedule[i].absenceReason === '') {
						return 'workday';
					} else {
						return 'dayoff';
					}
				}
			}

			return '';
		},

		async addSchedule() {
			if (this.addScheduleData.absenceReason !== '') {
				this.addScheduleData.beginTime = '';
				this.addScheduleData.endTime = '';
			} else {
				this.addScheduleData.beginTime = this.addScheduleData.beginTimeHour + ':' + this.addScheduleData.beginTimeMinute;
				this.addScheduleData.endTime = this.addScheduleData.endTimeHour + ':' + this.addScheduleData.endTimeMinute;

				if (!this.addScheduleData.beginTime.match(/^[0-9]{2}:[0-9]{2}$/)) {
					this.ADD_NOTIFICATION_TO_QUERY({
						type: 'error',
						title: 'Ошибка',
						message: 'Время начала работы не заполнено или имеет неправильный формат'
					});
					return;
				}
				if (!this.addScheduleData.endTime.match(/^[0-9]{2}:[0-9]{2}$/)) {
					this.ADD_NOTIFICATION_TO_QUERY({
						type: 'error',
						title: 'Ошибка',
						message: 'Время окончания работы не заполнено или имеет неправильный формат'
					});
					return;
				}
			}

			await this.HELPDESK_CREATE_STAFF_SCHEDULE(this.addScheduleData).then(() => {
				this.addScheduleData.dates = [];
				this.addScheduleData.beginTime = '';
				this.addScheduleData.endTime = '';
				this.addScheduleData.absenceReason = '';
				this.ADD_NOTIFICATION_TO_QUERY({type: 'success', title: 'Успех', message: 'Успешно'});
				this.getSchedule();
			}).catch((e) => {
				this.ADD_NOTIFICATION_TO_QUERY({
					type: 'error',
					title: 'Ошибка',
					message: 'Произошла ошибка'
				});
			});
		},
	},

	async mounted() {
		for (let q in this.$route.query) {
			if (q) {
				if (q === 'month' || q === 'year') {
					this.filters[q] = parseInt(this.$route.query[q]);
				} else if(q === 'backTo' || q === 'back_to') {
					this.backToUrl = this.$route.query[q];
				} else {
					this.filters[q] = this.$route.query[q];
				}
			}
		}

		if (typeof this.filters.month !== 'undefined' && typeof this.filters.year !== 'undefined') {
			this.calendarMonth = this.filters.month;
			this.calendarYear = this.filters.year;
		}

		if (this.$route.params.user_id !== '0') {
			await this.GET_USERS_FROM_API({id: this.$route.params.user_id}).catch(() => {
				this.ADD_NOTIFICATION_TO_QUERY({
					type: 'error',
					title: 'Ошибка',
					message: 'Произошла ошибка при получении пользователя'
				});
			});
			this.user = Object.assign({}, this.passportUsers[0]);
			this.addScheduleData.userId = this.user.id;

			await this.getSchedule();

			this.loaded = true;
		}
	},
};
</script>

<style>
.schedule-calendar td {
	width: 14.27%;
	border: 1px solid rgba(83, 101, 140, 0.33);
}
.schedule-calendar td.workday {
	background: #defbdb;
}
.schedule-calendar td.dayoff {
	background: #fff1f0;
}
.schedule-box {
	height: 50px;
	position: relative;
}
.schedule-box.disabled {
	color: #eee;
}
.schedule-box > .remove-btn {
	position: absolute;
	top: 0;
	right: 0;
}
</style>
