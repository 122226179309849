<template>
	<div class="element-box">
		<h5 class="form-header">
			Таблица пользователей
		</h5>
		<div class="form-desc">
			Выберите нужного пользователя и нажмите на кнопку действия<br>
			<router-link :to="{ name: routeNames.passport.users.create }">Создать нового пользователя</router-link>
		</div>
		<div class="table-responsive" style>

			<div class="controls-above-table">
				<div class="row">
					<div class="col-sm-6">
						<a class="btn btn-sm btn-secondary" v-b-modal="'filters-modal'" href="#">Фильтры</a>
						<b-modal
								id="filters-modal"
								title="Фильтрация пользователей"
								ok-title="Применить"
								cancel-title="Отмена"
								v-on:ok="applyFilters"
						>
							<b-form>
								<b-form-group id="users-id-filter-group" label="ID" label-for="users-id-filter">
									<b-form-input
											id="users-id-filter"
											v-model="filters.id"
											placeholder="Введите искомый id"
									></b-form-input>
								</b-form-group>
								<b-form-group id="users-login-filter-group" label="Логин"
															label-for="users-login-filter">
									<b-form-input
											id="users-login-filter"
											v-model="filters.login"
											placeholder="Введите искомый login"
									></b-form-input>
								</b-form-group>
								<b-form-group id="users-name-filter-group" label="ФИО"
															label-for="users-name-filter">
									<b-form-input
											id="users-name-filter"
											v-model="filters.name"
											placeholder="Введите ФИО"
									></b-form-input>
								</b-form-group>

								<b-form-group label="Вывод пользователей"
															label-for="users-disabled-filter">
									<b-form-select
											v-model="filters.disabled"
											:options="disabledSelectOptions"
									></b-form-select>
								</b-form-group>
								<b-form-group id="attribute-input-group" label="Атрибуты пользователя"
															label-for="user-attribute-input">
									<b-row class="mb-1 text-center">
										<b-col>Атрибут</b-col>
										<b-col>Значение</b-col>
										<b-col></b-col>
									</b-row>
									<div v-for="attr in filters.attributes" :key="attr.attribute">
										<b-row align-v="center" class="mb-1 text-center">
											<b-col>
												<b-select v-model="attr.attribute"
																	:options="attributeOptions"></b-select>
											</b-col>
											<b-col>
												<b-input v-model="attr.value"></b-input>
											</b-col>
											<b-col>
												<b-button class="btn btn-danger" @click="attributeDelete(attr)">
													Удалить
												</b-button>
											</b-col>
										</b-row>
									</div>
									<b-button class="btn btn-grey" @click="attributeAdd">Добавить</b-button>
								</b-form-group>
							</b-form>
						</b-modal>
					</div>
					<div class="col-sm-6">

						<form class="form-inline justify-content-sm-end">
							<b-form-select
									id="passport-users-get-limit"
									name="passport-users-get-limit"
									v-model="filters.limit"
									:options="limits"
									class="form-control form-control-sm rounded bright"
									@change="GET_USERS_FROM_API(filters)"
							></b-form-select>
						</form>
					</div>
				</div>
			</div>

			<table class="table table-lightborder">
				<thead>
				<tr>
					<th>
						Имя
					</th>
					<th>
						Логин
					</th>
					<th v-show="!IS_EXTERNAL()">
						Телеграм
					</th>
					<th class="text-right">
						Действия
					</th>
				</tr>
				</thead>
				<tbody v-for="user in passportUsers" :key="user.id">
				<tr>
					<td>
						{{ user.name }}
					</td>
					<td>
						{{ user.login }}
					</td>
					<td class="text-left" v-show="!IS_EXTERNAL()">
						{{
							getAttrValue(VUE_APP_PASSPORT_TG_LOGIN_ATTR, user.userAttributes) === '' ? 'Не задан' : getAttrValue(VUE_APP_PASSPORT_TG_LOGIN_ATTR, user.userAttributes)
						}}
					</td>
					<td class="row-actions text-right">
						<router-link v-if="canUpdatePassportUsers || passportSU" v-b-tooltip.html.top
												 title="Редактировать"
												 :to="{ name: routeNames.passport.users.update, params: { user_id: user.id }, query: filters} ">
							<i class="os-icon os-icon-ui-49"></i>
						</router-link>

						<a v-if="canUseHelpdeskTerminalDeactivatorApi" v-b-tooltip.html.top
							 title="Выйти из терминала"
							 v-b-modal="'terminal-user-'+user.id" class="warning">
							<i class="os-icon os-icon-lock"></i>
						</a>

						<a v-if="(canUpdatePassportUsers || passportSU) && user.disabled === 0" v-b-tooltip.html.top
							 title="Удалить"
							 v-b-modal="'delete-user-'+user.id" class="danger">
							<i class="os-icon os-icon-ui-15"></i>
						</a>

						<a v-if="(canUpdatePassportUsers || passportSU) && user.disabled === 1" v-b-tooltip.html.top
							 title="Восстановить"
							 v-b-modal="'revive-user-'+user.id">
							<i class="os-icon os-icon-ui-21"></i>
						</a>

						<b-modal
								:id="'delete-user-'+user.id"
								centered
								footer-class="p2"
								title="Подтвердите действие"
								size="sm"
								button-size="sm"
								ok-variant="danger"
								ok-title="Да"
								cancel-title="Нет"
								@ok="deleteUser(user)"
								@reset="resetDeleteModal"
								@show="resetDeleteModal"
						>
							Вы уверены что хотите удалить пользователя {{ user.login }} ?
							<br>
							<b-form-checkbox
									v-model="deleteTg"
									name="checkbox-1"
									v-show="getAttrValue(VUE_APP_PASSPORT_TG_ID_ATTR, user.userAttributes) !== ''"
							>
								Удалить из каналов телеграма
							</b-form-checkbox>
							<b-form-checkbox
									v-model="deleteVpn"
									name="checkbox-2"
									v-show="getAttrValue(VUE_APP_PASSPORT_VPN_ATTR, user.userAttributes) !== '' && canUseHelpdeskVpnApi"
							>
								Удалить из VPN
							</b-form-checkbox>
							<b-form-checkbox
									v-model="deleteLeo"
									name="checkbox-3"
									v-show="getAttrValue(VUE_APP_PASSPORT_TG_ID_ATTR, user.userAttributes) !== '' && getAttrValue(VUE_APP_PASSPORT_TG_LOGIN_ATTR, user.userAttributes) !== '' && canUseHelpdeskLeobotApi"
							>
								Удалить из бд леобота
							</b-form-checkbox>
						</b-modal>

						<b-modal
								:id="'terminal-user-'+user.id"
								centered
								footer-class="p2"
								title="Подтвердите действие"
								size="sm"
								button-size="sm"
								ok-variant="danger"
								ok-title="Да"
								cancel-title="Нет"
								@ok="stopTerminal(user)"
						>
							Вы уверены что хотите выключить терминал у пользователя {{ user.login }} ?
						</b-modal>

						<b-modal
								:id="'revive-user-'+user.id"
								centered
								footer-class="p2"
								title="Подтвердите действие"
								size="sm"
								button-size="sm"
								ok-variant="danger"
								ok-title="Да"
								cancel-title="Нет"
								@ok="reviveUser(user)"
								@reset="resetReviveModal"
								@show="resetReviveModal"
						>
							Вы уверены что хотите восстановить пользователя {{ user.login }} ?
							<br v-show="canUseHelpdeskVpnApi">
							<br v-show="canUseHelpdeskVpnApi">
							<b-form-checkbox
									v-model="reviveVpn"
									v-show="canUseHelpdeskVpnApi"
							>
								Восстановить VPN
							</b-form-checkbox>
							<b-form-group
								label="Новый логин VPN"
								v-show="canUseHelpdeskVpnApi && reviveVpn"
								label-for="user-vpn-pass"
							>
								<b-input-group>
									<b-form-input
										id="user-vpn-pass"
										v-model="user.login"
										disabled
									></b-form-input>
									<b-input-group-append>
										<b-button
											@click="copyToClipBoard(user.login)"
											class="os-icon os-icon-copy btn-grey"
										></b-button>
									</b-input-group-append>
								</b-input-group>
							</b-form-group>
							<b-form-group label="Новый пароль VPN"
								v-show="canUseHelpdeskVpnApi && reviveVpn"
								label-for="user-vpn-pass"
							>
								<b-input-group>
									<b-form-input
										id="user-vpn-pass"
										v-model="vpnPass"
										disabled
									></b-form-input>
									<b-input-group-append>
										<b-button
											@click="copyToClipBoard(vpnPass)"
											class="os-icon os-icon-copy btn-grey"
										></b-button>
									</b-input-group-append>
								</b-input-group>
							</b-form-group>
						</b-modal>
					</td>
				</tr>
				</tbody>
			</table>
		</div>
		<div class="controls-below-table">
			<div class="table-records-info">
			</div>
			<div class="table-records-pages">
				<ul>
					<li>
						<a href="#" @click="previous" v-if="canGetPrev">Назад</a>
					</li>
					<li>
						<a href="#" @click="next" v-if="canGetNext && passportUsers.length === filters.limit">Далее</a>
					</li>
				</ul>
			</div>
		</div>
	</div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import {routeNames} from '@/router/constants';
import {
	IS_EXTERNAL,
	VUE_APP_PASSPORT_TG_ID_ATTR,
	VUE_APP_PASSPORT_TG_LOGIN_ATTR,
	VUE_APP_PASSPORT_VPN_ATTR
} from '@/config';
import generator from 'generate-password';

export default {
	name: 'UserTable',
	data() {
		return {
			filters: {
				id: null,
				login: null,
				name: null,
				disabled: 0,
				attributes: [],
				limit: 10,
				offset: 0
			},
			limits: [
				{value: 10, text: 'Пользователей на странице'},
				{value: 10, text: '10'},
				{value: 15, text: '15'},
				{value: 30, text: '30'},
				{value: 50, text: '50'}
			],

			disabledSelectOptions: [
				{value: undefined, text: 'Все пользователи'},
				{value: 1, text: 'Только отключённые'},
				{value: 0, text: 'Только активные'},
			],

			attributeShow: false,
			attributeOptions: [],
			attributeError: [],

			deleteTg: true,
			deleteVpn: this.canUseHelpdeskVpnApi,
			deleteLeo: this.canUseHelpdeskLeobotApi,

			reviveVpn: this.canUseHelpdeskVpnApi,
			vpnPass: this.genPass(),

			canGetNext: true,
			canGetPrev: false,
			routeNames
		};
	},
	computed: {
		VUE_APP_PASSPORT_TG_LOGIN_ATTR() {
			return VUE_APP_PASSPORT_TG_LOGIN_ATTR;
		},
		VUE_APP_PASSPORT_TG_ID_ATTR() {
			return VUE_APP_PASSPORT_TG_ID_ATTR;
		},
		VUE_APP_PASSPORT_VPN_ATTR() {
			return VUE_APP_PASSPORT_VPN_ATTR;
		},
		...mapGetters([
			'passportUsers', 'canUpdatePassportUsers', 'canDeletePassportUsers',
			'passportSU', 'passportAttributes', 'canUseHelpdeskVpnApi', 'canUseHelpdeskLeobotApi',
			'canUseHelpdeskTerminalDeactivatorApi'
		]),
	},
	methods: {
		IS_EXTERNAL,
		...mapActions([
			'GET_USERS_FROM_API', 'ADD_NOTIFICATION_TO_QUERY', 'GET_ATTRIBUTES_FROM_API', 'TG_TERMINATE',
			'HELPDESK_DELETE_VPN_USER', 'UPDATE_USER', 'HELPDESK_CREATE_VPN_USER', 'LEO_BOT_TERMINATE',
			'HELPDESK_TERMINAL_TERMINATE'
		]),

		copyToClipBoard(value) {
			window.navigator.clipboard.writeText(value);
		},

		getAttrValue(id, attrs) {
			if (id === '' || IS_EXTERNAL() || !attrs) {
				return '';
			}
			const attr = attrs.find(e => e.attribute === id);
			if (attr) {
				return attr.value;
			}
			return '';
		},

		genPass() {
			return generator.generate({
				length: 16,
				numbers: true,
				excludeSimilarCharacters: true
			});
		},

		resetDeleteModal() {
			this.deleteTg = true;
			this.deleteVpn = this.canUseHelpdeskVpnApi;
			this.deleteLeo = this.canUseHelpdeskLeobotApi;
		},

		resetReviveModal() {
			this.reviveVpn = this.canUseHelpdeskVpnApi;
			this.vpnPass = this.genPass();
		},

		async applyFilters() {
			this.filters.limit = 10;
			this.filters.offset = 0;
			this.canGetNext = true;
			this.canGetPrev = false;
			await this.getUsers();
		},

		async stopTerminal(user) {
			await this.HELPDESK_TERMINAL_TERMINATE({uid: user.id}).catch(() => {
				this.ADD_NOTIFICATION_TO_QUERY({
					type: 'error',
					title: 'Ошибка',
					message: 'Произошла ошибка при вызове блокировки терминала'
				});
			});
		},

		async deleteUser(user) {
			let idAddedAttr = -1;
			if (this.deleteTg || this.deleteLeo) {
				const tgId = this.getAttrValue(VUE_APP_PASSPORT_TG_ID_ATTR, user.userAttributes);
				const tgLogin = this.getAttrValue(VUE_APP_PASSPORT_TG_LOGIN_ATTR, user.userAttributes);
				if (this.deleteTg) {
					if (tgId !== '' && tgId !== '-') {
						const deleted = await this.TG_TERMINATE({userId: tgId}).catch(() => {
							this.ADD_NOTIFICATION_TO_QUERY({
								type: 'error',
								title: 'Ошибка',
								message: `Произошла ошибка при удалении telegram пользователя ${tgId}`
							});
						});
						this.ADD_NOTIFICATION_TO_QUERY({
							type: 'success',
							title: 'Успех',
							message: `Пользователь ${tgId} удалён в ${deleted.data.removed} группах из ${deleted.data.all}`
						});
						idAddedAttr = user.userAttributes.findIndex(x => x.attribute === VUE_APP_PASSPORT_TG_ID_ATTR);
						if (idAddedAttr > -1) {
							user.userAttributes.splice(idAddedAttr, 1);
						}
					}
				}
				if (this.deleteLeo) {
					if (tgId !== '' && tgId !== '-' && tgLogin !== '' && tgLogin !== '-' && this.canUseHelpdeskLeobotApi) {
						await this.LEO_BOT_TERMINATE({userId: tgId, userLogin: tgLogin}).catch(() => {
							this.ADD_NOTIFICATION_TO_QUERY({
								type: 'error',
								title: 'Ошибка',
								message: `Произошла ошибка при удалении пользователя ${tgId} из бд леобота`
							});
						});
						this.ADD_NOTIFICATION_TO_QUERY({
							type: 'success',
							title: 'Успех',
							message: `Пользователь ${tgId} удалён из бд леобота`
						});
						idAddedAttr = user.userAttributes.findIndex(x => x.attribute === VUE_APP_PASSPORT_TG_ID_ATTR);
						if (idAddedAttr > -1) {
							user.userAttributes.splice(idAddedAttr, 1);
						}
						idAddedAttr = user.userAttributes.findIndex(x => x.attribute === VUE_APP_PASSPORT_TG_LOGIN_ATTR);
						if (idAddedAttr > -1) {
							user.userAttributes.splice(idAddedAttr, 1);
						}
					}
				}
			}
			if (this.deleteVpn) {
				const vpnLogin = this.getAttrValue(VUE_APP_PASSPORT_VPN_ATTR, user.userAttributes);
				if (vpnLogin !== '' && this.canUseHelpdeskVpnApi) {
					await this.HELPDESK_DELETE_VPN_USER({user: vpnLogin}).catch(() => {
						this.ADD_NOTIFICATION_TO_QUERY({
							type: 'error',
							title: 'Ошибка',
							message: `Произошла ошибка при удалении впн пользователя ${vpnLogin}`
						});
					});
					this.ADD_NOTIFICATION_TO_QUERY({
						type: 'success',
						title: 'Успех',
						message: `Пользователь ${vpnLogin} удалён из VPN`
					});
					idAddedAttr = user.userAttributes.findIndex(x => x.attribute === VUE_APP_PASSPORT_VPN_ATTR);
					if (idAddedAttr > -1) {
						user.userAttributes.splice(idAddedAttr, 1);
					}
				}
			}
			await this.UPDATE_USER({user: {id: user.id, disabled: 1, userAttributes: user.userAttributes}}).catch(() => {
				this.ADD_NOTIFICATION_TO_QUERY({
					type: 'error',
					title: 'Ошибка',
					message: 'Произошла ошибка при деактивации пользователя'
				});
			});
			this.ADD_NOTIFICATION_TO_QUERY({
				type: 'success',
				title: 'Успех',
				message: 'Пользователь успешно деактивирован'
			});
			await this.applyFilters();
		},

		async reviveUser(user) {
			if (this.reviveVpn) {
				let vpnLogin = this.getAttrValue(VUE_APP_PASSPORT_VPN_ATTR, user.userAttributes);
				
				if (vpnLogin === '') {
					vpnLogin = user.login;
					user.userAttributes.push({attribute: VUE_APP_PASSPORT_VPN_ATTR, value: vpnLogin});
				}

				await this.HELPDESK_CREATE_VPN_USER({
					user: vpnLogin,
					pass: this.vpnPass,
				}).then(() => {
					this.ADD_NOTIFICATION_TO_QUERY({
						type: 'success',
						title: 'Успех',
						message: 'Пользователь VPN успешно создан'
					});
				}).catch(() => {
					this.ADD_NOTIFICATION_TO_QUERY({
						type: 'error',
						title: 'Ошибка',
						message: 'Произошла ошибка при создании пользователя VPN'
					});
				});
			}

			await this.UPDATE_USER({user: {id: user.id, disabled: 0, userAttributes: user.userAttributes}}).catch(() => {
				this.ADD_NOTIFICATION_TO_QUERY({
					type: 'error',
					title: 'Ошибка',
					message: 'Произошла ошибка при восстановлении пользователя'
				});
			});
			this.ADD_NOTIFICATION_TO_QUERY({
				type: 'success',
				title: 'Успех',
				message: 'Пользователь успешно восстановлен'
			});
			await this.applyFilters();
		},

		async previous() {
			if (this.filters.offset >= this.filters.limit) {
				this.filters.offset -= this.filters.limit;
				await this.getUsers();
				this.canGetNext = true;
				if (this.filters.offset === 0) {
					this.canGetPrev = false;
				}
			}
		},

		async next() {
			this.canGetPrev = true;
			this.filters.offset += this.filters.limit;
			await this.getUsers();
			if (this.passportUsers.length < this.filters.limit) {
				this.canGetNext = false;
			}
		},

		async getUsers() {
			await this.GET_USERS_FROM_API(this.filters).catch(() => {
				this.ADD_NOTIFICATION_TO_QUERY({
					type: 'error',
					title: 'Ошибка',
					message: 'Произошла ошибка при получении списка пользователей'
				});
			});
			if (this.filters.offset > 0) {
				this.canGetPrev = true;
			}
		},

		attributeAdd() {
			let app = this;
			this.filters.attributes.forEach(function (attr) {
				let idAddedAttr = app.attributeOptions.findIndex(x => x.value === attr.attribute);
				if (idAddedAttr > -1) {
					app.attributeOptions[idAddedAttr].disabled = true;
				}
			});
			let i = this.filters.attributes.length;
			if (i === 0 || this.filters.attributes[i - 1].attribute !== null && this.filters.attributes[i - 1].value !== null) {
				this.filters.attributes.push({attribute: null, value: null});
			}
		},

		attributeDelete(attr) {
			let idAddedAttr = this.filters.attributes.findIndex(x => x.attribute === attr.attribute);
			if (idAddedAttr > -1) {
				this.filters.attributes.splice(idAddedAttr, 1);
			}
			let app = this;
			this.filters.attributes.forEach(function (attr) {
				let idAddedAttr = app.attributeOptions.findIndex(x => x.value === attr.attribute);
				if (idAddedAttr > -1) {
					app.attributeOptions[idAddedAttr].disabled = false;
				}
			});
		},

		attributeRefresh() {
			this.filters.attributes = [];
		},
	},

	async mounted() {
		for (let q in this.$route.query) {
			if (q) {
				this.filters[q] = this.$route.query[q];
			}
		}
		await this.getUsers();

		await this.GET_ATTRIBUTES_FROM_API({}).catch(() => {
			this.ADD_NOTIFICATION_TO_QUERY({
				type: 'error',
				title: 'Ошибка',
				message: 'Произошла ошибка при получении списка атрибутов'
			});
		});
		for (let i = 0; i < this.passportAttributes.length; i++) {
			this.attributeOptions.push({
				value: this.passportAttributes[i].code,
				text: this.passportAttributes[i].name + ' - ' + this.passportAttributes[i].code
			});
		}
	},
};
</script>

<style>

</style>
