<template>
	<div class="schedule-box">
		<div>
			{{ calendar.name }}
		</div>
		<template v-if="schedule !== null">
			<div v-if="schedule.absenceReason !== ''">
				{{ schedule.absenceReason }}
			</div>
			<div v-else>
				{{ schedule.startTime }} - {{ schedule.endTime }}
			</div>

			<div v-if="!calendar.isDisabled" class="remove-btn">
				<a
					v-b-tooltip.html.top
					title="Удалить"
					v-b-modal="'delete-schedule-'+schedule.id" class="danger">
					<i class="os-icon os-icon-ui-15"></i>
				</a>
			</div>
			<b-modal
				v-if="!calendar.isDisabled"
				:id="'delete-schedule-'+schedule.id"
				centered
				footer-class="p2"
				title="Подтвердите действие"
				size="sm"
				button-size="sm"
				ok-variant="danger"
				ok-title="Да"
				cancel-title="Нет"
				@ok="deleteSchedule(user.id, schedule.id)"
			>
				Удалить рабочее время?
			</b-modal>
		</template>
	</div>
</template>

<script>
import {mapActions} from 'vuex';

export default {
	name: 'ScheduleItem',
	props: {
		user: {
			type: Object,
			default: null,	
		},
		calendar: {
			type: Object,
			default: null,
		},
		schedule: {
			type: Object,
			default: null,
		},
	},
	emits: ['schedule-delete'],
	methods: {
		...mapActions([
			'HELPDESK_DELETE_STAFF_SCHEDULE', 'ADD_NOTIFICATION_TO_QUERY'
		]),

		async deleteSchedule(userId, scheduleId) {
			await this.HELPDESK_DELETE_STAFF_SCHEDULE({userId: userId, scheduleId: scheduleId}).catch(() => {
				this.ADD_NOTIFICATION_TO_QUERY({
					type: 'error',
					title: 'Ошибка',
					message: 'Произошла ошибка при удалении расписания'
				});
			});

			this.$emit('delete-schedule');
		},
	},
};
</script>

<style>

</style>
