<template>
	<div class="element-box">
		<h5 class="form-header">
			Расписание работы сотрудников {{ filterDate }}
			<template v-if="filterOffice">
				/ {{ filterOffice }}
			</template>
		</h5>
		<div class="table-responsive">

			<div class="controls-above-table">
				<div class="row align-items-center">
					<div class="col-sm-6">
						<div class="row align-items-center">
							<div class="col col-auto">
								<a class="btn btn-sm btn-secondary" v-b-modal="'filters-modal'" href="#">Фильтры</a>
								<b-modal
									id="filters-modal"
									title="Фильтрация пользователей"
									ok-title="Применить"
									cancel-title="Отмена"
									v-on:ok="applyFilters"
								>
									<b-form>
										<b-form-group id="users-id-filter-group" label="ID" label-for="users-id-filter">
											<b-form-input
												id="users-id-filter"
												v-model="filters.id"
												placeholder="Введите искомый id"
											></b-form-input>
										</b-form-group>
										<b-form-group id="users-login-filter-group" label="Логин"
																	label-for="users-login-filter">
											<b-form-input
												id="users-login-filter"
												v-model="filters.login"
												placeholder="Введите искомый login"
											></b-form-input>
										</b-form-group>
										<b-form-group
											id="users-name-filter-group"
											label="ФИО"
											label-for="users-name-filter"
										>
											<b-form-input
												id="users-name-filter"
												v-model="filters.name"
												placeholder="Введите ФИО"
											></b-form-input>
										</b-form-group>

										<b-form-group
											label="Вывод пользователей"
											label-for="users-disabled-filter"
										>
											<b-form-select
													v-model="filters.disabled"
													:options="disabledSelectOptions"
											></b-form-select>
										</b-form-group>
										<b-form-group label="Офисы" 
											id="branches-office_id-filter" 
											label-for="branches-office_id-filter"
										>
											<b-form-tags id="branches-office_id-filter" v-model="userOffices" no-outer-focus class="mb-2">
												<template v-slot="{ tags, disabled, addTag, removeTag }">
													<ul v-if="tags.length > 0" class="list-inline d-inline-block mb-2">
														<li v-for="tag in tags" :key="tag" class="list-inline-item">
															<b-form-tag
																@remove="onOfficeDeleteClick({ option: tag, removeTag: removeTag })"
																:disabled="disabled"
																variant="info"
															>
																{{ JSON.parse(tag).name }}
															</b-form-tag>
														</li>
													</ul>

													<b-dropdown class="wrap-dropdown" size="sm" variant="outline-secondary" block menu-class="w-100">
														<template #button-content>
															Добавление офисов
														</template>
														<b-dropdown-form @submit.stop.prevent="() => {}">
															<b-form-group
																label="Поиск офисов"
																label-for="branch-search-input"
																label-cols-md="auto"
																class="mb-0"
																label-size="sm"
																:description="searchOfficesDesc"
																:disabled="disabled"
															>
																<b-form-input
																	v-model="searchOffices"
																	id="branch-search-input"
																	type="search"
																	size="sm"
																	autocomplete="off"
																	placeholder="Начните вводить название офиса"
																></b-form-input>
															</b-form-group>
														</b-dropdown-form>
														<b-dropdown-item-button
															v-for="office in officesAvailableOptions"
															:key="office.id"
															@click="onOfficeAddClick({ option: office, addTag: addTag })"
														>{{ office.name }}
														</b-dropdown-item-button>
														<b-dropdown-text v-if="officesAvailableOptions.length === 0">
															Офисы не найдены
														</b-dropdown-text>
													</b-dropdown>
												</template>
											</b-form-tags>
										</b-form-group>
										<div class="row">
											<div class="col-sm-3">
												<b-form-group label="Месяц от" label-for="users-month-filter">
													<b-form-select
														v-model="filters.monthFrom"
														:options="months"
													></b-form-select>
												</b-form-group>
											</div>
											<div class="col-sm-3">
												<b-form-group label="Год от" label-for="users-year-filter">
													<b-form-select
														v-model="filters.yearFrom"
														:options="years"
													></b-form-select>
												</b-form-group>
											</div>
											<div class="col-sm-3">
												<b-form-group label="Месяц до" label-for="users-month-filter">
													<b-form-select
														v-model="filters.monthTo"
														:options="months"
													></b-form-select>
												</b-form-group>
											</div>
											<div class="col-sm-3">
												<b-form-group label="Год до" label-for="users-year-filter">
													<b-form-select
														v-model="filters.yearTo"
														:options="years"
													></b-form-select>
												</b-form-group>
											</div>
										</div>
									</b-form>
								</b-modal>
							</div>
							<div class="col">
								<div class="row align-items-center">
									<div class="col col-auto pr-1">
										<b-form-checkbox
											id="fill-days-disabled-input"
											v-model="filters.filledDaysEnabled"
											:value="true"
											:unchecked-value="false"
										></b-form-checkbox>
									</div>
									<div class="col col-auto pl-0">
										<label for="fill-days-disabled-input" class="d-block mb-0">заполнено на N дней</label>
									</div>
									<div class="col">
										<b-form-input
											v-model="filters.filledDaysValue"
											class="p-1 w-25"
											:disabled="!filters.filledDaysEnabled"
											type="number"
										></b-form-input>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="col-sm-6">
						<form class="form-inline justify-content-sm-end">
							<b-form-select
								id="passport-users-get-limit"
								name="passport-users-get-limit"
								v-model="filters.limit"
								:options="limits"
								class="form-control form-control-sm rounded bright"
								@change="getUsers()"
							></b-form-select>
						</form>
					</div>
				</div>
			</div>
			<div class="calendar-container">
				<table class="table table-lightborder calendar-table">
					<tbody>
						<tr class="thead">
							<td class="sticked">
								<div>
									Имя
								</div>
							</td>
							<td class="sticked-subdivision">
								<div>
									Подр.
								</div>
							</td>
							<td>
								<div>
									Офис
								</div>
							</td>
							<td v-for="(c, i) in generateCalendar" :key="i">
								<div>
									{{ c.name }}
								</div>
							</td>
						</tr>
						<tr v-for="user in passportUsers" :key="user.id" :class="getUserNotFilled(user.id)">
							<td class="sticked">
								<div>
									<router-link
										title="Редактировать"
										:to="{ name: routeNames.passport.schedule.update, params: { user_id: user.id }, query: filters}"
									>
										{{ user.name }}
									</router-link>
								</div>
							</td>
							<td class="sticked-subdivision">
								<div>
									{{ getUserSubdivision(user) }}
								</div>
							</td>
							<td>
								<div>
									{{ getUserOffice(user) }}
								</div>
							</td>
							<td v-for="(c, i) in generateCalendar" :key="i" :class="getScheduleColor(user.id, c.date)">
								<div v-html="getScheduleItem(user.id, c.date)" />
							</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
		<div class="controls-below-table">
			<div class="table-records-info">
			</div>
			<div class="table-records-pages">
				<ul>
					<li>
						<a href="#" @click="previous" v-if="canGetPrev">Назад</a>
					</li>
					<li>
						<a href="#" @click="next" v-if="canGetNext && passportUsers.length === filters.limit">Далее</a>
					</li>
				</ul>
			</div>
		</div>
	</div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import {routeNames} from '@/router/constants';

export default {
	name: 'ScheduleTable',
	data() {
		const d = new Date();
		const years = [];

		if (d.getFullYear() > 2024) {
			const diff = d.getFullYear() - 2024;
			for (let i = 0; i < diff; i += 1) {
				years.push(2024 + i);
			}
		}

		for (let i = 0; i < 3; i += 1) {
			years.push(d.getFullYear() + i);
		}

		return {
			filters: {
				id: null,
				ids: null,
				login: null,
				name: null,
				disabled: 0,
				officeId: [],
				monthFrom: d.getMonth(),
				yearFrom: d.getFullYear(),
				monthTo: d.getMonth(),
				yearTo: d.getFullYear(),
				filledDaysEnabled: false,
				filledDaysValue: null,
				limit: 10,
				offset: 0
			},
			limits: [
				{value: 10, text: 'Пользователей на странице'},
				{value: 10, text: '10'},
				{value: 15, text: '15'},
				{value: 30, text: '30'},
				{value: 50, text: '50'}
			],

			disabledSelectOptions: [
				{value: undefined, text: 'Все пользователи'},
				{value: 1, text: 'Только отключённые'},
				{value: 0, text: 'Только активные'},
			],

			months: [
				{value: 0, text: 'Январь'},
				{value: 1, text: 'Февраль'},
				{value: 2, text: 'Март'},
				{value: 3, text: 'Апрель'},
				{value: 4, text: 'Май'},
				{value: 5, text: 'Июнь'},
				{value: 6, text: 'Июль'},
				{value: 7, text: 'Август'},
				{value: 8, text: 'Сентябрь'},
				{value: 9, text: 'Октябрь'},
				{value: 10, text: 'Ноябрь'},
				{value: 11, text: 'Декабрь'},
			],
			years,

			canGetNext: true,
			canGetPrev: false,
			calendarMonthFrom: d.getMonth(),
			calendarYearFrom: d.getFullYear(),
			calendarMonthTo: d.getMonth(),
			calendarYearTo: d.getFullYear(),
			selectedOfficeId: [],
			routeNames,

			markedUsers: [],

			officesOptions: [],
			searchOffices: '',
			userOffices: [],
		};
	},
	watch: {
		'filters.filledDaysEnabled': function() {
			this.checkMarkedUsers();
		},
		'filters.filledDaysValue': function() {
			this.checkMarkedUsers();
		},
	},
	computed: {
		...mapGetters([
			'passportUsers',
			'helpdeskBranches',
			'helpdeskAvailableBranchesWithCityId',
			'staffSchedule',
			'staffOffices',
		]),
		generateCalendar() {
			const days = [];

			const dFrom = new Date(this.calendarYearFrom, this.calendarMonthFrom, 1);
			const dTo = new Date(this.calendarYearTo, this.calendarMonthTo, 1);
			const maxDaysTo = new Date(dTo.getFullYear(), dTo.getMonth() + 1, 0).getDate();
			dTo.setDate(maxDaysTo);

			let dFromMonth = dFrom.getMonth() + 1;
			let dFromDateStr = `${dFrom.getFullYear()}`;
			dFromDateStr += dFromMonth < 10 ? `0${dFromMonth}` : `${dFromMonth}`;
			dFromDateStr += dFrom.getDate() < 10 ? `0${dFrom.getDate()}` : `${dFrom.getDate()}`;
			let dFromDate = parseInt(dFromDateStr, 10);

			const dToMonth = dTo.getMonth() + 1;
			let dToDateStr = `${dTo.getFullYear()}`;
			dToDateStr += dToMonth < 10 ? `0${dToMonth}` : `${dToMonth}`;
			dToDateStr += dTo.getDate() < 10 ? `0${dTo.getDate()}` : `${dTo.getDate()}`;
			let dToDate = parseInt(dToDateStr, 10);

			let counter = 1;
			while (dFromDate < dToDate) {
				const dNew = new Date(dFrom.getFullYear(), dFrom.getMonth(), counter);

				dFromMonth = dNew.getMonth() + 1;
				dFromDateStr = `${dNew.getFullYear()}`;
				dFromDateStr += dFromMonth < 10 ? `0${dFromMonth}` : `${dFromMonth}`;
				dFromDateStr += dNew.getDate() < 10 ? `0${dNew.getDate()}` : `${dNew.getDate()}`;
				dFromDate = parseInt(dFromDateStr, 10);

				const d = dNew.getDate();
				const m = dNew.getMonth() + 1;
				days.push({
					date: dNew.getFullYear() + '-' + (m < 10 ? `0${m}` : m) + '-' + (d < 10 ? `0${d}` : d),
					name: (d < 10 ? `0${d}` : d) + '.' + (m < 10 ? `0${m}` : m),
				});

				counter += 1;
			}

			return days;
		},
		filterDate() {
			if (this.calendarYearFrom !== this.calendarYearTo) {
				const m1 = this.months.find(month => month.value === this.calendarMonthFrom);
				const m2 = this.months.find(month => month.value === this.calendarMonthTo);
				if (m1 && m2) {
					return `${m1.text} ${this.calendarYearFrom} - ${m2.text} ${this.calendarYearTo}`;
				}
			} else if (this.calendarMonthFrom !== this.calendarMonthTo) {
				const m1 = this.months.find(month => month.value === this.calendarMonthFrom);
				const m2 = this.months.find(month => month.value === this.calendarMonthTo);
				if (m1 && m2) {
					return `${m1.text} - ${m2.text} ${this.calendarYearFrom}`;
				}
			} else {
				const m = this.months.find(month => month.value === this.calendarMonthFrom);
				if (m) {
					return `${m.text} ${this.calendarYearFrom}`;
				}
			}

			return '';
		},
		filterOffice() {
			if (this.selectedOfficeId && this.selectedOfficeId.length) {
				const offices = [];

				for (let i = 0; i < this.selectedOfficeId.length; i += 1) {
					const office = this.helpdeskBranches.find(o => o.id === this.selectedOfficeId[i]) || null;
					if (office) {
						offices.push(office.name);
					}
				}

				if (offices.length) {
					return offices.join(', ');
				}
			}

			return null;
		},
		officesCriteria() {
			return this.searchOffices.trim().toLowerCase();
		},
		officesAvailableOptions() {
			const criteria = this.officesCriteria;
			if (criteria) {
				return this.officesOptions.filter(opt => opt.name.toLowerCase().indexOf(criteria) > -1);
			}
			return this.officesOptions;
		},
		searchOfficesDesc() {
			if (this.officesCriteria && this.officesAvailableOptions.length === 0) {
				return 'Офисы не найдены';
			}
			return '';
		},
	},
	methods: {
		...mapActions([
			'GET_USERS_FROM_API',
			'ADD_NOTIFICATION_TO_QUERY',
			'HELPDESK_GET_BRANCHES_FROM_API',
			'HELPDESK_GET_STAFF_SCHEDULE_FROM_API',
			'HELPDESK_GET_STAFF_OFFICES_FROM_API',
		]),

		async applyFilters() {
			this.calendarMonthFrom = this.filters.monthFrom;
			this.calendarYearFrom = this.filters.yearFrom;
			this.calendarMonthTo = this.filters.monthTo;
			this.calendarYearTo = this.filters.yearTo;

			this.filters.limit = 10;
			this.filters.offset = 0;
			this.canGetNext = true;
			this.canGetPrev = false;

			await this.getUsers();
		},

		async previous() {
			if (this.filters.offset >= this.filters.limit) {
				this.filters.offset -= this.filters.limit;
				await this.getUsers();

				this.canGetNext = true;
				if (this.filters.offset === 0) {
					this.canGetPrev = false;
				}
			}
		},

		async next() {
			this.canGetPrev = true;
			this.filters.offset += this.filters.limit;
			await this.getUsers();

			if (this.passportUsers.length < this.filters.limit) {
				this.canGetNext = false;
			}
		},

		async getUsers() {
			if (this.userOffices && this.userOffices.length > 0) {
				let values = [];
				this.userOffices.forEach(function callback(userOffice) {
					let office = userOffice;
					if (typeof office != 'object') {
						office = JSON.parse(office);
					}
					values.push(office.id);
				});

				this.filters.officeId = values;
			} else {
				this.filters.officeId = [];
			}

			const filters = JSON.parse(JSON.stringify(this.filters));

			if (this.filters.officeId && this.filters.officeId.length) {
				const offices = await this.getUsersByOfficeId(this.filters.officeId.join(','));

				if (offices && offices.length) {
					const userIds = [];

					for (let i = 0; i < offices.length; i += 1) {
						userIds.push(offices[i].userId);
					}

					filters.ids = userIds.join(',');
				}

				filters.officeId = null;
			}

			this.selectedOfficeId = this.filters.officeId;

			await this.GET_USERS_FROM_API(filters).catch(() => {
				this.ADD_NOTIFICATION_TO_QUERY({
					type: 'error',
					title: 'Ошибка',
					message: 'Произошла ошибка при получении списка пользователей'
				});
			});

			const ids = [];

			for (let i = 0; i < this.passportUsers.length; i += 1) {
				ids.push(this.passportUsers[i].id);
			}

			if (ids.length > 0) {
				await this.getSchedule(ids);
				await this.getStaffOffices(ids);
			}

			if (this.filters.offset > 0) {
				this.canGetPrev = true;
			}
			await this.checkMarkedUsers();
		},

		async getSchedule(users) {
			const currentMonthFrom = this.filters.monthFrom + 1;
			const currentMonthTo = this.filters.monthTo + 1;
			const dateParamFrom = this.filters.yearFrom.toString() + '-' + (currentMonthFrom < 10 ? '0' + currentMonthFrom : currentMonthFrom);
			const dateParamTo = this.filters.yearTo.toString() + '-' + (currentMonthTo < 10 ? '0' + currentMonthTo : currentMonthTo);

			await this.HELPDESK_GET_STAFF_SCHEDULE_FROM_API({userId: users.join(','), fromMonth: dateParamFrom, toMonth: dateParamTo}).catch(() => {
				this.ADD_NOTIFICATION_TO_QUERY({
					type: 'error',
					title: 'Ошибка',
					message: 'Произошла ошибка при получении списка расписаний'
				});
			});
		},

		async getStaffOffices(users) {
			await this.HELPDESK_GET_STAFF_OFFICES_FROM_API({userId: users.join(',')}).catch(() => {
				this.ADD_NOTIFICATION_TO_QUERY({
					type: 'error',
					title: 'Ошибка',
					message: 'Произошла ошибка при получении списка офисов сотрудников'
				});
			});
		},

		async getUsersByOfficeId(officeId) {
			const res = await this.HELPDESK_GET_STAFF_OFFICES_FROM_API({officeId: officeId}).catch(() => {
				this.ADD_NOTIFICATION_TO_QUERY({
					type: 'error',
					title: 'Ошибка',
					message: 'Произошла ошибка при получении списка офисов сотрудников'
				});
			});

			return res;
		},

		getScheduleItem(userId, date) {
			for (let i = 0; i < this.staffSchedule.length; i += 1) {
				if (this.staffSchedule[i].userId === userId && this.staffSchedule[i].date === date) {
					if (this.staffSchedule[i].absenceReason !== '') {
						return this.staffSchedule[i].absenceReason;
					} else {
						return this.staffSchedule[i].startTime + '<br>' + this.staffSchedule[i].endTime;
					}
				}
			}

			return '';
		},

		getUserOffice(user) {
			const offices = [];
			const addedOffices = [];
			const result = this.staffOffices.find(o => o.userId === user.id) || null;

			if (result) {
				if (result.offices.length > 0) {
					for (let i = 0; i < result.offices.length; i += 1) {
						for (let j = 0; j < this.helpdeskBranches.length; j += 1) {
							if (this.helpdeskBranches[j].id === result.offices[i].id) {
								const f = addedOffices.find(o => o === this.helpdeskBranches[j].id) || null;
								if (f) {
									continue;
								}

								offices.push(this.helpdeskBranches[j].name);
								addedOffices.push(this.helpdeskBranches[j].id);
							}
						}
					}
				}
			}

			return offices.join(', ');
		},

		getUserSubdivision(user) {
			if (user.userAttributes && user.userAttributes.length) {
				for (let i = 0; i < user.userAttributes.length; i += 1) {
					if (user.userAttributes[i].code === 'subdivision') {
						return user.userAttributes[i].value;
					}
				}
			}

			return '';
		},

		getUserNotFilled(userId) {
			if (this.markedUsers.length) {
				const r = this.markedUsers.find(u => u === userId) || null;
				if (r) {
					return 'not-filled';					
				}
			}

			return '';
		},

		checkMarkedUsers() {
			this.markedUsers = [];
			
			const users = [];

			if (this.filters.filledDaysEnabled && this.filters.filledDaysValue) {
				const steps = parseInt(this.filters.filledDaysValue, 10);
				const now = new Date();

				for (let i = 0; i < steps; i += 1) {
					const nextDate = new Date(now.getFullYear(), now.getMonth(), now.getDate() + i);

					const m = nextDate.getMonth() + 1;
					let dateStr = `${nextDate.getFullYear()}`;
					dateStr += m < 10 ? `-0${m}`: `-${m}`;
					dateStr += nextDate.getDate() < 10 ? `-0${nextDate.getDate()}`: `-${nextDate.getDate()}`;

					for (let j = 0; j < this.passportUsers.length; j += 1) {
						const f = this.staffSchedule.find(s => s.userId === this.passportUsers[j].id && s.date === dateStr) || null;
						if (!f) {
							users.push(this.passportUsers[j].id);
						}
					}
				}
			}
			
			this.markedUsers = users;
		},

		onOfficeAddClick({option, addTag}) {
			if (this.userOffices.findIndex(x => x.id === option.id) > -1) {
				this.ADD_NOTIFICATION_TO_QUERY({
					type: 'info',
					title: 'Информация',
					message: 'Офис уже добавлен'
				});
				return;
			}

			this.userOffices.push({id: option.id, name: option.name});

			let id = this.officesOptions.findIndex(x => x.id === option.id);
			if (id > -1) {
				this.officesOptions.splice(id, 1);
			}

			addTag(JSON.stringify(option));
			this.searchOffices = '';
		},

		onOfficeDeleteClick({option, removeTag}) {
			// option приходит из tag-ов в виде строки, поэтому парсим
			let officeToRemove = JSON.parse(option);

			let isAddedOffice = this.userOffices.findIndex(x => x.id === officeToRemove.id);
			if (isAddedOffice > -1) {
				this.userOffices.splice(isAddedOffice, 1);
			}

			this.officesOptions.push(officeToRemove);
			this.officesOptions.sort((a, b) => a.name.normalize().localeCompare(b.name.normalize()));

			removeTag(option);
		},

		getScheduleColor(userId, date) {
			for (let i = 0; i < this.staffSchedule.length; i += 1) {
				if (this.staffSchedule[i].userId === userId && this.staffSchedule[i].date === date) {
					if (this.staffSchedule[i].absenceReason === '') {
						return 'workday';
					} else {
						return 'dayoff';
					}
				}
			}

			return '';
		},
	},

	async mounted() {
		for (let q in this.$route.query) {
			if (q) {
				if (q === 'monthFrom' || q === 'yearFrom' || q === 'monthTo' || q === 'yearTo') {
					this.filters[q] = parseInt(this.$route.query[q]);
				} else {
					this.filters[q] = this.$route.query[q];
				}
			}
		}

		this.calendarMonthFrom = this.filters.monthFrom;
		this.calendarYearFrom = this.filters.yearFrom;
		this.calendarMonthTo = this.filters.monthTo;
		this.calendarYearTo = this.filters.yearTo;

		await this.getUsers();

		await this.HELPDESK_GET_BRANCHES_FROM_API({limit: 1000, project: 'all'}).catch(() => {
			this.ADD_NOTIFICATION_TO_QUERY({
				type: 'error',
				title: 'Ошибка',
				message: 'Произошла ошибка при получении списка филиалов'
			});
		});

		for (let office of this.helpdeskAvailableBranchesWithCityId) {
			this.officesOptions.push({id: office.id, name: office.name});
		}

		this.officesOptions.sort((a, b) => a.name.normalize().localeCompare(b.name.normalize()));

		const ids = [];

		for (let i = 0; i < this.passportUsers.length; i += 1) {
			ids.push(this.passportUsers[i].id);
		}

		if (ids.length > 0) {
			await this.getSchedule(ids);
			await this.getStaffOffices(ids);
		}
	},
};
</script>

<style>
.calendar-container {
	position: relative;
	max-width: 100%;
	overflow: auto;
	min-width: 100%;
	width: 85vw;
}
.calendar-table {
	th, td {
		flex: 1 0 60px;
		padding: 0 !important;
	}

	td.sticked {
		position: -webkit-sticky;
		position: sticky;
		left: 0;
		width: 220px;
		background: #ffffff;
		z-index: 1;
	}

	td.sticked-subdivision {
		position: -webkit-sticky;
		position: sticky;
		left: 200px;
		width: 60px;
		background: #ffffff;
		z-index: 1;
	}

	th:nth-child(1), td:nth-child(1), th:nth-child(3), td:nth-child(3) {
		flex: 1 0 200px;
	}

	td > div {
		position: relative;
		padding: 0.75rem 0.25rem;
	}

	td.workday {
		background: #defbdb;
	}

	td.dayoff {
		background: #fff1f0;
	}

	tr.thead > td {
		text-transform: uppercase;
		border-top: none;
		border-left: none;
		border-right: none;
		border-bottom: 1px solid #999;
	}

	tr.thead > td > div {
		padding: 0 0 0.25rem 0;
	}

	thead, tbody {
		display: flex;
		flex-direction: column;
	}

	tr {
		display: flex;
		flex: 1 0;
	}
	tr.not-filled > td {
		background: #fff1f0;
	}
}
</style>
